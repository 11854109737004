<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>学术管理</el-breadcrumb-item>
      <el-breadcrumb-item>课程管理</el-breadcrumb-item>
      <el-breadcrumb-item>课程详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <el-card>
      <courseFileManage @gobackClicked="goBack"></courseFileManage>
    </el-card>
    <!-- <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="图文课程" name="first">
        <courseFileManage @gobackClicked="goBack"></courseFileManage>
      </el-tab-pane>
      <el-tab-pane label="视频课程" name="second">
        <courseVideoManage @gobackClicked="goBack"></courseVideoManage>
      </el-tab-pane>
    </el-tabs> -->
  </div>
</template>
<script>
import courseFileManage from '@/components/courseContentDetail/course_file_manage'
export default {
  created () {
  },
  components: {
    courseFileManage
  },
  data () {
    return {
      activeName: 'first'
    }
  },

  methods: {
    goBack () {
      this.$router.back()
    },
    // 类型选择
    handleClick (tab, event) {
      console.log(tab, event)
    }
  }
}
</script>
<style lang="less" scoped>
// .courseImage {
//   width: 100px;
//   height: 100px;
// }
// .avatar{
//   width: 100px;
//   height: 100px;
// }
// .avatar-uploader-icon {
//   font-size: 28px;
//   color: #8c939d;
//   width: 100px;
//   height: 100px;
//   line-height: 100px;
//   text-align: center;
//   border: 1px solid #8c939d;
// }
</style>
